.donate-form {
  p {
    margin: 0 0 1rem;
  }

  label img {
    display: none !important;
  }

  #dmm_dd,
  .dmm_nomc,
  .field-recurring_interval,
  br {
    display: none !important;
  }

  input {
    border-radius: 10px;
    border: 1px $secondary solid !important;
  }
}

@include media-breakpoint-up(sm) {
  .donate-form select,
  .donate-form .field-currency > span:last-child {
    width: 50% !important;
  }
}
