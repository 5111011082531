.page__header-image {
  position: relative;
  max-width: 745px;
  min-height: 250px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
}

.header-image__img {
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

@include media-breakpoint-up(md) {
  .page__header-image {
    min-height: 360px;
  }
}
