.search {
  position: relative;

  .search__form {
    display: block;

    button {
      font-size: 120%;
      position: absolute;
      height: 100%;
      top: 0;
      right: .75rem;
      background: transparent;
      border: 0;
      padding: 0;
      color: $gray-400;
      line-height: 1.8;
    }
  }
}

.navbar .search {
  min-width: 300px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.nav-overlay .search input {
  padding-left: 1rem;
  border-top: 1px $gray-200 solid;
  border-bottom: 1px $gray-200 solid;
}
