.odb-kids {
  margin-bottom: 3rem;
}

.odb-kids .row {
  align-items: center;
  gap: 2rem;
  flex-direction: column;
}

.odb-kids__title {
  font-size: 2.187rem;
  color: #1A264C;
}

.odb-kids__content,
.odb-kids__image {
  text-align: center;
}

@include media-breakpoint-up(md) {
  .odb-kids__title {
    font-size: 2.5rem;
  }

  .odb-kids__content {
    text-align: left;
  }
}
