body {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
blockquote.wp-block-quote {
  color: $primary;
  margin-bottom: .5em;
  font-family: 'PraterSansTwo', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
}

h1, .h1 {
  font-size: 2.187rem;
  line-height: 1.142;
}

h2, .h2 {
  font-size: 1.874rem;
  line-height: 1.2
}

h3, .h3 {
  font-size: 1.562rem;
  line-height: 1.2
}

h4 {
  font-size: 1.093rem;
  line-height: 1.2
}

p.excerpt {
  margin: 0;
}

a:hover {
  color: $primary;
  text-decoration: none;
}

blockquote.wp-block-quote {
  color: $secondary;
  font-size: inherit;
  font-weight: bold;
  margin: 0;
  padding: 1.5rem 0;

  p {
    margin: 0;
  }
}

.wp-block-pullquote {
  position: relative;
  margin: 0;
  padding: 2rem 0 !important;

  blockquote {
    position: relative;
    z-index: 5;
    color: #fff;
    background: #e3efe9;
    padding: 2rem 6rem;
    margin: 0 -6rem;
  }

  p {
    color: $darkBlue;
    text-align: left;
    font-size: inherit !important;
    font-weight: bold;
    margin-bottom: 0;
  }

  p strong {
    color: $primary;
    font-family: 'PraterSansTwo', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }

  &:before,
  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
  }

  &:before {
    width: 387px;
    height: 68px;
    left: -200px;
    top: 100px;
    background: url('./../../../statics/scripture4.png');
  }

  &:after {
    width: 425px;
    height: 218px;
    right: -270px;
    bottom: 0;
    background: url('./../../../statics/scripture5.png');
  }
}


@include media-breakpoint-up(xl) {
  h1, .h1 {
    font-size: 4.375rem;
    line-height: 1.142;
  }

  h2, .h2 {
    font-size: 3.125rem;
    line-height: 1.2
  }

  blockquote.wp-block-quote {
    position: absolute;
    text-align: left;
    max-width: 235px;
    left: calc(-235px - 3rem);
    padding: 0;
  }

  blockquote.wp-block-quote.right {
    text-align: right;
    left: auto;
    right: calc(-235px - 3rem);
  }
}



