form {
  .form-control {
    border-radius: 0;
    border:0;
    border-bottom: 1px $border-color solid;
  }

  .field br {
    display: none;
  }

  label {
    display: none;
  }

  textarea.form-control {
    height: 120px !important;
    resize: none;
  }

  ::placeholder {
    font-style: italic;
    font-weight: 400;
  }

  .form-control,
  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border-color: $primary !important;
  margin: 1rem 0 0;
  padding: .5rem 1rem;
}
