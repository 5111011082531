.arrow-right,
.arrow-left {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('./../../../statics/icons/arrow-blue.svg') no-repeat center center;
  transition-property: transform;
  transition-duration: 100ms;
}

.dark .arrow-right,
.dark .arrow-left {
  background: transparent url('./../../../statics/icons/arrow-darkblue.svg') no-repeat center center;
}

.secondary .arrow-right,
.secondary .arrow-left {
  background: transparent url('./../../../statics/icons/arrow-green.svg') no-repeat center center;
}

.arrow-left {
  transform: rotate(180deg) !important;
}

.btn--fab .arrow-left {
  transform: translate(-50%, -50%) rotate(180deg) !important;
}


