.list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px $primary solid;
}

.list__item {
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px $primary solid;
  display: flex;
}

.item__number {
  min-width: 1.5rem;
  margin-right: .75rem;
}

.list__link {
  padding: .5rem 0;
  display: flex;
  width: 100%;
}



.item__label {
  flex: 1 1 auto;
}

.list__link .item__actions {
  position: relative;
  width: 3rem;
}

.list__link .item__actions .icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  transition: right 200ms linear;
}

.list__link:hover .item__actions .icon {
  right: 2px;
}

@include media-breakpoint-up(md) {
  .list__item {
    font-size: 1.15rem;
  }
}
