header.header {
  position: relative;
  z-index: 200;

  .container {
    position: relative;
    z-index: 20;
    padding: 2rem 2.25rem;
  }

  nav {
    position: relative;
    z-index: 3000;
  }
}

.brand {
  flex: 0 0 auto;
}

.brand__link {
  display: block;
  cursor: pointer;
}

.brand__image {
  pointer-events: none;
  display: block;
  height: 28px;
}

.header__cta {
  display: none;
}

.header__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%; // 16/9 display
}

.header__image {
  max-height: 400px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.header__description {
  max-width: 50%;
}

@include media-breakpoint-up(lg) {
  .header {
    .container {
      padding: 2rem 0;
    }
  }

  .header__cta {
    display: initial;
  }
}

