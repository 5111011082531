$primary: #3E97FF !default;
$secondary: #8FC0A9 !default;
$yellow: #FFBE12 !default;
$darkBlue: #1A264C !default;
$darkGray: #292929;
$black: #000 !default;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$input-placeholder-color: $gray-400 !default;
$btn-border-radius: 0 !default;
$link-color: $black !default;
