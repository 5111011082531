.nav {
  display: none;
  flex: 1 0 auto;
  min-height: 38px;
}

.navbar {
  padding: 0;
}

.nav__items, .nav__item {
  display: flex;
}

.nav__item {
  position: relative;
}

.navbar .nav {
  .nav__item {
    color: $darkBlue;
    margin-left: 2rem;
  }

  .nav__link {
    color: inherit;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .nav__link .arrow-right {
    left: 0;
    transition: left 150ms linear;
  }

  .nav__link:hover .arrow-right {
    left: 3px;
  }

  .nav__item:hover,
  .nav__item--active {
    color: $primary;

    a {
      color: inherit;
    }
  }

  .nav__items--child-menu {
    display: none;
    position: absolute;
    left: -1.5rem;
    top: 100%;
    min-width: max-content;
    padding: 1.5rem;
    z-index: 10;

    &:before {
      position: absolute;
      content: "";
      background: white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: .625rem;
      filter: drop-shadow(0px 10px 10px #B4B4B4);
    }

    .nav__item {
      margin-left: 0;
    }

    .nav__link {
      width: 100%;
      padding: .25rem 0;
      font-weight: normal;
      justify-content: space-between;
    }

    .nav__icon {
      margin-left: 4rem;
    }
  }

  .nav__item:hover > .nav__items--child-menu {
    display: block;
  }
}

.nav-overlay .nav {
  display: flex;

  .nav__item {
    flex-wrap: wrap;
  }

  .nav__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: .5rem 1rem;
    border-bottom: 1px $primary solid;
  }

  .nav__icon {
    position: relative;
    top: 5px;
  }

  .nav__items--child-menu .nav__link {
    padding: .5rem 1rem .5rem 1.5rem;
  }
}

.nav-overlay .nav__items {
  flex-direction: column;
}

.nav__items {
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
}

.nav__item:first-child {
  margin-left: 0;
}

.nav__toggler {
  position: relative;
  padding: 0;
  border: 0;
  border-radius: 0;
  width: 24px;
  height: 19px;
  cursor: pointer;
  z-index: 2000;

  &:before {
    position: absolute;
    width: 36px;
    height: 36px;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: all .4s;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $primary;
    transition: all .4s;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: calc(50% - 1px);
  }

  span:nth-child(3) {
    bottom: 0;
  }
}

.nav__toggler--active {
  &:before {
    opacity: 1;
  }

  span:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(-45deg);
  }
  span:nth-child(2) {
    top: calc(50% - 1px);
    opacity: 0;
  }
  span:nth-child(3) {
    top: calc(50% - 1px);
    bottom: auto;
    transform: rotate(45deg);
  }
}

.nav-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 5.5rem 0 0;
  font-size: 1rem;
  z-index: 1050;
  background: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity 250ms ease-out;

  &.nav-overlay--active {
    display: flex;
    visibility: visible;
    opacity: 1;
    flex-direction: column;
    transition: opacity 250ms ease-in;
  }

  .nav-overlay__body {
    flex: 1 0 auto;
    padding: 1rem;
  }
}

.nav-overlay__body {
  background: #fff;
}

body.admin-bar .nav-overlay {
  top: 46px;
}

#wpadminbar {
  position: fixed !important;
}

@include media-breakpoint-up(sm) {
  body.admin-bar .nav-overlay {
    top: 32px;
  }
}


@include media-breakpoint-up(lg) {
  .nav-overlay,
  .nav__toggler {
    display: none;
  }

  .navbar .nav {
    display: flex;
  }


}
