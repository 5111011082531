.thumbnail__spacer {
  position: relative;
  padding-bottom: 100%;
}

.thumbnail__img,
.thumbnail__border1,
.thumbnail__border2,
.thumbnail__scriptures {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 129.189%;
  padding-top: 129.189%;
  border-radius: 99999px;
  z-index: 15;
}

.thumbnail__gradient {
  z-index: 15;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(299.9deg, #8FC0A9 32.76%, #3E97FF 91.82%);
}

.thumbnail--blue .thumbnail__gradient {
  background: linear-gradient(299.9deg, #1A264C 32.76%, #3E97FF 91.82%);
}

.thumbnail__border1,
.thumbnail__scriptures {
  width: 195.855%;
  padding-top: 195.855%;
}

.thumbnail__border1 {
  border: 1px $yellow solid;
}

.thumbnail__border2 {
  border: 1px $secondary solid;
}

.thumbnail__border1,
.thumbnail__border2 {
  opacity: .3;
}

.thumbnail__scriptures {
  background: transparent url('../../../statics/scripture1.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: 2;
  border-radius: 0;
}

.thumbnail__img {
  width: 129.189%;
  padding-top: 129.189%;
  z-index: 20;
  background: transparent url('../../../statics/mask-man.png') no-repeat center center;
  background-size: 100% 100%;
}

.thumbnail__masked-img {
  position: absolute;
  z-index: 50;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.thumbnail__masked-img img {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.thumbnail--blue {
  .thumbnail__border1 {
    border-color: #fff;
  }

  .thumbnail__border1,
  .thumbnail__border2 {
    z-index: 15;
  }

  .thumbnail__scriptures {
    display: none;
  }

  .thumbnail__img {
    background: none;
  }

  .thumbnail__img img {
    position: absolute;
    width: 63%;
    top: 55%;
    left: 62%;
    transform: translate(-50%, -50%);
  }
}


