.testimonials {
  z-index: 100;
  width: 100%;
  bottom: 4rem;
}

.testimonials__body {
  min-height: 170px;
}

.testimonials__author {
  color: $primary;
  font-weight: bold;
}

@include media-breakpoint-up(lg) {
  .testimonials {
    max-width: 375px;
    bottom: 5rem;
    left: calc(75% - 1.125rem);
    position: absolute;
    transform: translateX(-50%);
  }

  .testimonials__body {
    min-height: 140px;
  }
}
