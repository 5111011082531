@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";

html {
  font-size: 16px;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  color: $darkBlue;
}

a {
  color: inherit;
}

.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.layout header,
.layout footer {
  flex-shrink: 0;
}

.layout main {
  flex: 1 0 auto;
}

.icon-large {
  font-size: 1.5rem;
}

main {
  width: 100%;
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}


/**
 * BACKGROUND COLORS
 */
.bg-gray-900 {
  background-color: $gray-900
}
.bg-black {
  background-color: $black
}
.bg-gray {
  background-color: $gray-200;
}
.bg-primary-25p {
  background-color: #cfe5ff;
}
.bg-secondary-25p {
  background-color: #e3efe9
}

/**
 * BORDER COLORS
 */
.border-grey-400 {
  border-color: $gray-400 !important;
}
.border-grey-900 {
  border-color: $gray-900 !important;
}


.socials {
  font-size: 1rem;

  a:hover {
    color: inherit;
    opacity: .8;
  }

  i {
    font-size: 1.2rem;
  }
}





.category__list {
  .list-group__item {

    .item__content {
      max-width: 900px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .title {
      flex: 1 1 auto;
      padding-right: 1rem;
    }

    .link {
      display: block;
    }

    .number {
      vertical-align: top;
      flex: 0 0 auto;
      min-width: 2rem;
    }

    .icon {
      flex: 0 0 auto;
      font-size: 1.5rem;
    }
  }
}

.container {
  @include media-breakpoint-down(lg) {
    max-width: none;
  }
}

@import "layout/font";
@import "layout/form";
@import "layout/list";
@import "layout/page";
@import "layout/button";
@import "layout/icons";
@import "layout/card";

@import "components/header";
@import "components/nav";
@import "components/search";
@import "components/contact-bar";
@import "components/newsletter-form";
@import "components/footer";
@import "components/sponsor-list";
@import "components/donate-form";
@import "components/testimonials";
@import "components/thumbnail";
@import "components/category-bar";
@import "components/question-bar";
@import "components/odb-kids";

@import "components/post/tile";
@import "components/post/youtube-player";
@import "components/post/social-share";
@import "components/post/header-image";
