.button-group,
.wp-block-buttons {
  margin-top: 1rem;
}

.wp-block-buttons > .wp-block-button {
  margin-right: .75em !important;
  margin-bottom: .75em !important;
}

button {
  background-color: transparent !important;
}

.btn, .wp-block-button__link {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  padding: .687rem 1.25rem !important;
}

.btn--green:not(.wp-block-button),
.wp-block-button .wp-block-button__link,
.btn--green .wp-block-button__link {
  color: $white !important;
  background-color: $secondary !important;
  filter: drop-shadow(0px 3px 6px $secondary);
  transition: filter 200ms;
}

.btn--green:not(.wp-block-button):hover,
.wp-block-button .wp-block-button__link:hover,
.btn--green .wp-block-button__link:hover {
  filter: drop-shadow(0px 5px 8px $secondary);
}

.btn--yellow:not(.wp-block-button),
.btn--yellow .wp-block-button__link {
  color: $darkBlue !important;
  background-color: $yellow !important;
  filter: drop-shadow(0 3px 6px $yellow);
  transition: filter 200ms;
}

.btn--yellow:not(.wp-block-button):hover,
.btn--yellow .wp-block-button__link:hover {
  filter: drop-shadow(0px 5px 8px $yellow);
}

.wp-block-button__link {
  font-size: 1rem !important;
}

.wp-block-button__link,
.btn--rounded {
  border-radius: 2rem !important;
}

.btn--fab {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  padding: 0 !important;
}

.btn--fab i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-group .btn:not(:last-child) {
  margin-right: .75em;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
