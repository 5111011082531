.category-bar {
  position: relative;
  z-index: 100;
  margin-bottom: -3rem;

  .category-bar__heading {
    text-align: center;
    margin-bottom: 3rem;
  }

  .container {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .category-bar__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .category {
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: center;
    min-height: 320px;
  }

  .category:last-child {
    margin-bottom: 0;
  }

  .category__link {
    margin-top: 1.5rem;
    display: inline-block;
  }
  .category__icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
  }
  .category__link:hover {
    color: inherit;
  }
  .category__range {
    font-size: .75rem;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(md) {
  .category-bar {
    margin-bottom: -8rem;

    .category-bar__body {
      flex-direction: row;
    }

    .category {
      margin: 0;
      width: 31%;
    }
  }
}
