.footer {
  padding-top: 1rem;
  margin-top: 1rem;
}

.about-site__link {
  color: $primary;
}

.about-site__brand img {
  margin-bottom: 1rem;
  max-width: 124px; // need to be set correctly
}

.footer__title {
  margin-top: -3px;
  text-transform: uppercase;
}

.footer__bar {
  padding: 1rem 2.25rem;
}

.footer__bar:before {
  position: relative;
  top: -1rem;
  display: block;
  content: "";
  border-top: 1px $darkGray solid;
}

.footer__widgets {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 2.25rem;
}

.footer__widget {
  margin-bottom: 1rem;
}

.extra-links {
  width: 100%;
}

.extra-links .list-inline-item:not(:last-child) {
  display: block;
  margin: 0;
}

.newsletter__widget {
  .wpcf7-validation-errors {
    display: none !important;
  }

  .wpcf7-not-valid-tip {
    color: $danger;
    font-style: italic;
    padding: .375rem .75rem;
  }

  .actions {
    flex: 1;
    z-index: 5;
  }

  .actions .btn {
    width: 100%;
  }
}


@include media-breakpoint-up(md) {
  .footer {
    .author {
      order: 0;
      flex: 0 0 auto;
    }

    .extra-links {
      width: auto;
      flex: 0 0 auto;
    }

    .extra-links .list-inline-item:not(:last-child) {
      margin-right: 2rem;
      display: inline-block;
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    margin-top: 2rem;
    padding-top: 2rem;
  }

  .footer__widgets {
    flex-direction: row;
    align-content: end;
  }

  .footer__widget {
    align-self: end;
  }
}

@include media-breakpoint-up(xl) {
  .footer__widgets,
  .footer__bar {
    padding: 0;
  }
}
