.page__container {
  position: relative;
  z-index: 100;
  padding: 0 2.25rem;
}

.page__thumbnail {
  margin: 3rem -1rem;
}

.post__thumbnail {
  width: 100%;
  height: auto;
}

.page__media {
  position: relative;
  z-index: 2000;
  padding: 0 0 2rem !important;
}

.page__footer {
  padding: 1rem .5rem;
}

.page__title {
  max-width: 850px;
  margin: 0 auto 2rem;
}

.page__post-excerpt {
  margin-bottom: 2rem;
}

.page__body {
  position: relative;
}

.page__body ol {
  margin-top: -1px;
}

.page__body:before {
  display: none;
  position: absolute;
  z-index: 20;
  content: '';
  top: 450px;
  left: -375px;
  width: 600px;
  height: 562px;
  background-image: url('../../../statics/scripture2.png');
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.page--single .page__body:before {
  top: -100px;
  transform: none;
}

.page__post-excerpt {
  max-width: 555px;
  margin: 0 auto 2rem;
  font-weight: bold;

  p:last-child {
    margin-bottom: 0;
  }
}

.page__article {
  position: relative;
  padding-bottom: 3rem;

  h2, h3 {
    font-size: 1.25em;
    margin: 1.5rem 0 0;
  }

  h2:first-child, h3:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.page__header-article {
  position: relative;
  z-index: 100;
  padding: 0;
  font-size: 1rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.page__nav {
  display: flex;
  font-weight: bold;
  margin-bottom: 2rem;

  .navigation__link {
    color: inherit;
    position: relative;
    top: 1px;
    display: block;
    margin-left: 2rem;
    margin-right: 1rem;
  }

  .navigation__link .arrow-left {
    left: 0;
    transition: left 200ms linear;
  }

  .navigation__link .arrow-right {
    left: 0;
    transition: left 200ms linear;
  }

  .navigation__link:hover .arrow-left {
    left: -3px;
  }

  .navigation__link:hover .arrow-right {
    left: 3px;
  }

  .navigation__link:hover {
    opacity: .85;
  }

  .navigation__label {
    position: relative;
    top: -.5rem;
    margin: 0 .5rem;
    display: none;
  }

  .navigation__link--disabled,
  .navigation__link--disabled:hover{
    opacity: .25;
  }

  .navigation__link:last-child {
    margin: 0;
  }
}

.page__nav--center {
  padding: 0 0 3rem;
  margin: 0 0 -3rem;
  justify-content: center;

  .navigation__link {
    order: 0;
    margin: 0;
  }

  > div {
    order: 1;
    margin: 0 1rem
  }

  .navigation__link:last-child {
    order: 2
  }
}

.page__header {
  position: relative;
  margin-bottom: 2rem;
}

.page__header:not(.page__header--without):after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 15vw;
  background: white;
  bottom: 0;
}

.page__curved {
  z-index: 10;
  width: 100%;
  padding-top: 10vw;
  background-image: url("data:image/svg+xml,%3Csvg width='1005' height='95' viewBox='0 0 1005 95' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M448 92.2059C265.6 92.2059 73.3333 30.7353 0 0L3.05176e-05 95L1005 95V37.0976C974.671 33.4269 940.964 31.201 904 31.201C706.5 31.201 676 92.2059 448 92.2059Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 10vw;
  position: absolute;
}

@include media-breakpoint-up(md) {
  .page__nav .navigation__label {
    display: initial;
  }

  .page__header-article {
    padding-right: 2rem;
  }

  .page__nav--center {
    > div {
      margin: 0 4rem
    }
  }

  .page__article--small {
    max-width: 555px;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(lg) {
  .page__header {
    position: relative;
    padding: 2rem 0 10vw;
    margin: 0 auto;

    .container {
      padding: 0 2.25rem;
    }
  }

  .page__header--without {
    //padding-bottom: 5.5rem !important;
    margin-bottom: 2rem !important;
  }

  .page__header--without .page__curved {
    bottom: 0;
  }

  .page__body:before {
    display: block;
  }

  .page__header-article {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 50%;
  }

  .page__thumbnail {
    padding: 0 0 5rem;
    margin: 0;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 50%;
  }
}

@include media-breakpoint-up(xl) {
  .page__header {
    margin: 0 auto;
    padding: 4rem 0 10vw;
  }

  .page .page__header {
    padding-bottom: 0;
  }

  .page--single .page__header {
    background-image: url('./../../../statics/scripture3.png');
    background-repeat: no-repeat;
    background-position: calc(100% + 1.5rem) top;
    padding-bottom: 0;
  }

  .btn-nav {
    font-size: 1.4rem;
  }
}
