.card {
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  filter: drop-shadow(0 3px 10px rgba(62, 151, 255, .5));
  transition: filter 200ms linear;
}

.card:hover {
  filter: drop-shadow(0 6px 10px rgba(62, 151, 255, .5));
}

.card_image {
  position: relative;
  padding-top: 48.076%;

  img {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card a {
  color: inherit;
}

.card__title {
  font-size: 1.25rem;
}

.card__body {
  padding: 2rem 1.875rem;
  min-height: 225px;
}

.card--secondary-drop-shadow {
  filter: drop-shadow(0 3px 10px $secondary);
}


