.youtube-player {
  position: relative;
  width: 100%;
  max-width: 745px;
}

.youtube-player:before {
  display: block;
  content: '';
  width: 100%;
  aspect-ratio: 16 / 9;
}

.youtube-player__iframe {
  border: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
