.question-bar {
  position: relative;
  padding: 6rem 0 5rem;
}

.question-bar .container {
  position: relative;
  z-index: 100;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.question-bar h2,
.question-bar p {
  margin-bottom: 2rem;
}

.question-bar p:last-child {
  margin-bottom: 0;
}

.question-bar:before {
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  content: '';
  padding-top: 2rem;
  background-image: url('./../../../statics/curved-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  image-rendering: -webkit-optimize-contrast;
}

.page--single .question-bar:before {
  background-image: url('./../../../statics/curved-single-bg.png');
  padding-top: 55px;
}

.question-bar__image-col {
  margin-bottom: 3rem;
}

.question-bar__image {
  order: 0;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 10px;
}

.question-bar__image img {
  width: 100%;
  object-fit: cover;
}

@include media-breakpoint-up(md) {
  .question-bar {
    padding-top: 12rem;
    padding-bottom: 5rem;
  }

  .page--single .question-bar {
    padding-top: 8rem;
  }

  .question-bar:before {
    padding-top: 8rem;
  }

  .question-bar__image-col {
    margin-bottom: 0;
  }

  .question-bar__image {
    min-height: 400px;
  }

  .question-bar__image img {
    min-height: 400px;
  }

  .question-bar__image-col {
    order: 1
  }
}
